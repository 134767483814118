<template>
  <b-row>
    <b-col cols="12" md="6" class="mx-auto">
      <b-card :header="started ? 'Your Meeting Link' : 'Start New Meeting'">
        <b-row v-if="!started">
          <b-col cols="12" md="12" class="mb-2" v-if="isLoading"
            >Creating Meeting, Please wait...</b-col
          >
          <b-col cols="12" md="12" class="form-group" v-if="!isLoading">
            <label for="meetingName"
              >Meeting Name / Topic / Title
              <span class="text-danger">*</span></label
            >
            <b-form-input v-model="meetingName" />
          </b-col>
          <b-col cols="12" md="12" class="mb-2" v-if="!isLoading">
            <label for="yourName"
              >Your Name <span class="text-danger">*</span></label
            >
            <b-form-input v-model="yourName" />
          </b-col>
          <b-col cols="12" md="6">
            <b-button
              @click="startPrivateMeeting"
              :disabled="!meetingName || !yourName || isLoading"
              block
              variant="primary"
              >Start Private Meeting</b-button
            >
          </b-col>
          <b-col cols="12" md="6">
            <b-button
              @click="startPublicMeeting"
              :disabled="!meetingName || !yourName || isLoading"
              block
              variant="success"
              >Start Public Meeting</b-button
            >
          </b-col>
          <b-col cols="12" md="6">
            Fields indicated with <span class="text-danger">*</span> are
            required
          </b-col>
        </b-row>
        <b-row v-if="started">
          <b-col cols="12" class="form-group">
            <label for="meetingLink"
              >Send following link to users to join the meeting</label
            >
            <input
              class="form-control"
              type="text"
              disabled
              :value="`https://nftbplus.com/live/${meetingType}/${$store.getters['auth/user']._id}`"
            />
          </b-col>
          <b-col cols="12" md="12" class="form-group" v-if="!isLoading">
            <label for="meetingName"
              >Meeting Name / Topic / Title
              <span class="text-danger">*</span></label
            >
            <b-form-input v-model="meetingName" disabled />
          </b-col>
          <b-col cols="12" md="12" class="mb-2" v-if="!isLoading">
            <label for="yourName"
              >Your Name <span class="text-danger">*</span></label
            >
            <b-form-input v-model="yourName" />
          </b-col>
          <b-col cols="12" md="6">
            <b-button
              :disabled="isRejoining || yourName == ''"
              block
              variant="success"
              @click="reJoinMeeting"
              >{{ isRejoining ? "Rejoining..." : "Rejoin Meeting" }}</b-button
            >
          </b-col>
          <b-col cols="12" md="6">
            <b-button
              block
              variant="danger"
              @click="endMeeting"
              :disabled="isRejoining || isEnding"
              >{{ isEnding ? "Ending..." : "End Meeting" }}</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { throws } from "assert";
import axios from "axios";
import { BRow, BCol, BForm, BFormInput, BCard, BButton } from "bootstrap-vue";
export default {
  name: "StartMeeting",
  data() {
    return {
      meetingName: "",
      yourName: "",
      isLoading: false,
      meetingType: "public",
      started: false,
      url: "",
      isRejoining: false,
      isEnding: false,
    };
  },
  methods: {
    async reJoinMeeting() {
      try {
        this.isRejoining = true;
        const formData = new FormData();
        formData.append("fullName", this.yourName);
        const { data } = await axios.post("/meetings/join/owner", formData);
        this.started = true;
        this.url = data.url;
        window.open(data.url, "_blank");
      } catch (e) {
        console.log(e);
      } finally {
        this.isRejoining = false;
      }
    },

    async endMeeting() {
      try {
        this.isEnding = true;

        const { data } = await axios.get("/meetings/end");
        this.started = false;
        this.meetingName = "";
        this.yourName = "";
        this.url = "";
      } catch (e) {
        console.log(e);
      } finally {
        this.isRejoining = false;
        this.isEnding = false;
      }
    },

    async checkExistingMeeting() {
      try {
        const { data } = await axios.get("/meetings/info");
        if (data.meetingExists != false) {
          this.started = true;
          this.meetingName = data.meetingData.meetingName;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async startMeeting() {
      try {
        const formData = new FormData();
        formData.append("meetingName", this.meetingName);
        formData.append("fullName", this.yourName);
        const { data } = await axios.post("/meetings", formData);
        this.started = true;
        this.isLoading = false;
        this.url = data.url;
        window.open(data.url, "_blank");
      } catch (e) {
        console.log(e);
      }
    },

    async startPrivateMeeting() {
      this.isLoading = true;
      this.meetingType = "private";
      this.startMeeting();
    },
    async startPublicMeeting() {
      this.isLoading = true;
      this.meetingType = "public";
      this.startMeeting();
    },
  },
  mounted() {
    this.checkExistingMeeting();
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BCard,
    BButton,
  },
};
</script>
